import _module from 'module.js'
import _ from 'lodash'

class FeaturesService {
  constructor (Restangular, portalConfig, portalApi) {
    'ngInject'
    this.portalApi = portalApi
  }

  getContextFeature (context, featureFlag) {
    return this.portalApi.pbx
      .pbxs(context)
      .one('features')
      .one(featureFlag)
      .get()
  }

  getAllContextFeatures (context) {
    return this.portalApi.pbx
      .pbxs(context)
      .one('features')
      .get()
      .then(response => response.plain())
  }

  deleteContextFeature (context, featureFlag) {
    return this.portalApi.pbx
      .pbxs()
      .one('features')
      .one(featureFlag)
      .remove()
  }

  setContextFeature (context, featureFlag, settingState) {
    return this.portalApi.pbx
      .pbxs(context)
      .one('features')
      .one(featureFlag)
      .customPUT({
        setting: settingState,
        context: context,
        feature: featureFlag
      })
  }
}

export const FEATURE_FLAGS = {
  adminItDashboard: 'legacy.admin.it.dashboard',
  callReportRecordingsEnabled: 'pbx.permissions.call-recordings',
  contactCenter: 'cc.ui',
  contactsEnabled: 'pbx.contacts',
  e911Enabled: 'pbx.e911-service-enabled',
  e911V3CallFlow: 'e911.v3.call-flow',
  e911V3Configuration: 'e911.v3.configuration',
  encryptProvisioning: 'ua.encrypt-provisioning',
  gdprEnabled: 'pbx.gdpr',
  hideAllowWebLogin: 'pbx.hide-allow-web-login',
  internalRegions: 'internal.regions',
  manageGroupsPerm: 'pbx.admin.managegroupsperm',
  manageSeatsPerm: 'pbx.admin.manageseatsperm',
  manageSettingsPerm: 'pbx.admin.managesettingsperm',
  manageUsersPerm: 'pbx.admin.manageusersperm',
  newCallReports: 'c3r.cr.ui',
  ordrPbxadminv5TabsOrders: 'ordr.pbxadminv5.tabs.orders',
  pbxAdminLocation: 'pbx.admin.location',
  pstnOutboundEnabled: 'pbx.pstn.outbound',
  secureCalling: 'ua.secure-calling',
  secureDirectory: 'ua.secure-url',
  securePassword: 'ua.organization-password',
  sharedAppearance: 'line.shared-appearance',
  sipTrunks: 'admin.sip-trunks',
  smsCampaignRegistration: 'sms.campaign.registration',
  tryNewGoToAdmin: 'gotoadmin.try-new',
  userSyncEnabled: 'pbx.user-sync-enabled',
  vfmAccessCodeSize: 'admin.vfm.access-code-size',
  virtualFaxMachineLargeFaxesEnabled: 'pbx.virtual-fax-machine.large-faxes',
  virtualFaxMachinePermissionsEnabled: 'pbx.virtual-fax-machine.permissions',
  virtualFaxMachineStorage: 'pbx.virtual-fax-machine.storage',
  virtualReceptionistV1: 'pbx.virtual-receptionist.v1',
  zohoZia: 'jci.ambassador.v1.provider.ZohoZia',
}

_module
  .service('featuresService', FeaturesService)
  .constant('FEATURE_FLAGS', FEATURE_FLAGS)
