export default class BannerController {
  constructor(globalState, $rootScope) {
    'ngInject'

    this.globalState = globalState
    this.index = 0;

    $rootScope.$on('pbxChanged', () => {
      this.index = 0
    })
  }

  prev() {
    if (this.index - 1 == -1) {
      this.index = this.messages.length - 1;
    } else {
      this.index--;
    }
  }

  next() {
    if (this.index + 1 == this.messages.length) {
      this.index = 0;
    } else {
      this.index++;
    }
  }

  dismiss() {
    localStorage.setItem('isBannerShowing', false);
    this.globalState.isBannerShowing = false;
  }
}
